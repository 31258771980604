<template>
  <div></div>
</template>

<script>
/**
 * Base logic of maintainers components
 * @author Cristian Molina
 */
import sellersJson from "@/assets/json/sellers";
import countrysJson from "@/assets/json/countrys";
import consumersJson from "@/assets/json/consumers";
import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc"); // dependent on utc plugin
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "util-maintainers",
  data: () => ({
    isEditing: false,
    object: null,
    headers: [],
    items: [],
    formPanel: false,
    showPanel: false,
    dialog: false,
    validForm: false,
    typeValues: [],
    searchValues: {},
    sellers: [],
    consumers: [],
    countrys: [],
    dayjs: dayjs,
    priorityColor: {
      red: {
        from: 0,
        to: 0,
        color: "error",
      },
      yellow: {
        from: 0,
        to: 0,
        color: "warning",
      },
      green: {
        from: 0,
        to: 0,
        color: "success",
      },
    },
  }),
  watch: {
    /**
     * @description Watch searchvalue
     * @param {any} v
     * @author Cristian Molina
     */
    searchValues(v) {
      if (v?.country) {
        dayjs.tz.setDefault(v.country.timezone);
      }
    },
    /**
     * @description Watch formPanel param for clean object or editing param.
     * @param {any} v
     * @author Cristian Molina
     */
    formPanel(v) {
      if (!v) {
        this.object = {};
        this.isEditing = false;
      }
    },
    /**
     * @description watch show panel param.
     * @param {any} v
     * @author Cristian Molina
     */
    showPanel(v) {
      if (!v) {
        this.object = {};
      }
    },
  },
  methods: {
    parseDateStringToISO(v) {
      try {
        return dayjs.tz(v);
      } catch (err) {
        return v;
      }
    },
    /**
     * @description Load combobox of sellers, consumers and countrys
     * @author Cristian Molina
     */
    loadComboBox() {
      this.sellers = [...sellersJson];
      this.consumers = [...consumersJson];
      this.countrys = [...countrysJson];
    },
    /**
     * @description check form and call getall
     * @author Cristian Molina
     */
    searchData() {
      if (this.$refs.searchForm.validate()) {
        console.log(this.searchValues);
        this.getAll();
      }
    },
    /**
     * @description Get priority colors in properties "priority"
     * @author Cristian Molina
     */
    getPriorityColors() {
      let min = 999999999;
      let max = 0;
      this.headers.forEach((header) => {
        if (header.value.toLowerCase().search("priority") > 0) {
          this.items.forEach((item) => {
            if (item[header.value] < 999999999 && item[header.value] > 0) {
              if (item[header.value] < min) {
                min = item[header.value];
              } else if (item[header.value] > max) {
                max = item[header.value];
              }
            }
          });
        }
      });
      let maxLessMin = max - min;
      let half = maxLessMin / 2 + min;
      let halfStep = maxLessMin * 0.2;
      this.priorityColor.green.from = min;
      this.priorityColor.green.to = half - halfStep;
      this.priorityColor.yellow.from = half - halfStep;
      this.priorityColor.yellow.to = half + halfStep;
      this.priorityColor.red.from = half + halfStep;
      this.priorityColor.red.to = max;
    },
    /**
     * @description generate headers used in tables
     * @author Cristian Molina
     */
    generateHeaders() {
      this.headers = [];
      if (this.items?.length > 0) {
        const item = this.items[0];
        Object.keys(item).forEach((key) => {
          let object = {
            text: "",
            value: "",
            align: "start",
            width: 0,
            type: "",
            required: "",
          };

          if (this.typeValues[key] && this.typeValues[key].showInTable) {
            object.type = this.typeValues[key]?.type;
            object.required = this.typeValues[key]?.required;
            object.text = this.typeValues[key].translate;
            object.value = key;
            object.width = this.typeValues[key].translate.length * 7 + 60;
            this.headers.push(object);
          }
        });
        this.headers.push({
          text: "Acciones",
          value: "actions",
          width: "actions".length * 7 + 100,
          sortable: false,
        });
      }
      console.log(this.headers);
    },
    /**
     * @description get a values from one array with coincidence
     * @param {any} value object to compare
     * @param {any} array array with value required
     * @returns {any} element found
     * @author Cristian Molina
     */
    getValueFromArray(value, array) {
      let res = value;
      array.forEach((element) => {
        if (element.value === value) {
          res = element;
        }
      });
      return res;
    },
    /**
     * @description Show object in panel form
     * @param {any} v
     * @author Cristian Molina
     */
    showObject(v) {
      if (v) {
        setTimeout(() => {
          this.formPanel = false;
          this.object = Object.assign({}, v);
          this.showPanel = true;
          this.dialog = true;
        }, 500);
      }
    },
    /**
     * @description Open edit panel of object
     * @param {any} v object to load in form
     * @param {any} isEditing if is editing or new object
     * @author Cristian Molina
     */
    openForm(v, isEditing) {
      if (v) {
        setTimeout(() => {
          this.showPanel = false;
          this.object = Object.assign({}, v);
          this.formPanel = true;
          this.isEditing = isEditing;
          this.dialog = true;
        }, 500);
      }
    },
    /**
     * @description close both panels
     * @author Cristian Molina
     */
    closePanel() {
      this.formPanel = false;
      this.showPanel = false;
      this.dialog = false;
    },
  },
};
</script>
